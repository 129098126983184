<template>
  <b-card v-if="data" no-body class="card-developer-meetup">
    <div class="bg-light-primary rounded-top text-center p-2">
      <b-img
        :src="require('@/assets/images/saitco-img/cpu.png')"
        alt="Meeting Pic"
        height="170"
      />
    </div>
    <b-card-body>
      <!-- metting header -->
      <div class="text-center">
        <div class="my-auto">
          <b-card-title class="mb-25 text-primary">
            {{ data.data.name }} | {{ data.data.id }}
          </b-card-title>
        </div>
      </div>
      <!--/ metting header -->

      <!-- media -->
      <b-media no-body class="mt-2">
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="HashIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.serialNumber") }}</h6>
          <small>{{ data.data.reference_number }}</small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="MapPinIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.countryOfOrigin") }}</h6>
          <small>{{ data.data.made_in ? data.data.made_in.name : null }}</small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="BarChart2Icon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.brand") }}</h6>
          <small>{{ data.data.brand }}</small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="ToolIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.maintenanceDuration") }}</h6>
          <small>{{ data.data.maintenanceType ? data.data.maintenanceType.name : $t("g.noData") }}</small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="BoxIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.status") }}</h6>
          <small>{{ data.data.in_maintenance ? $t('g.InMaintenance') : $t('g.OutMaintenance') }}</small>
        </b-media-body>
      </b-media>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
