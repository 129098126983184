<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />

    <b-row v-else class="justify-content-md-center">
      <b-col md="9">
        <basic-informations :data="deviceData" />
        <informations :data="deviceData" />
        <maintenance-log />
      </b-col>
      <b-col md="3">
        <device-informations :data="deviceData" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BImg } from "bootstrap-vue";
import DeviceImg from "@/assets/images/saitco-img/cpu.png";
import DeviceInformations from "./deviceInformations.vue";
import BasicInformations from "./basicInformations.vue";
import MaintenanceLog from "./maintenanceLog.vue";
import Informations from "./informations.vue";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BImg,
    DeviceInformations,
    BasicInformations,
    MaintenanceLog,
    Loading,
    Error,
    Informations
  },
  data() {
    return {
      currentId: null,
      DeviceImg: DeviceImg,
      deviceData: null,
      isLoading: true,
      isError: false,
    };
  },
  created() {
    this.currentId = this.$route.params.id;
    this.$http
      .get(`admin/devices/${this.currentId}`)
      .then((res) => {
        this.deviceData = res.data;
        this.isLoading = false;
        this.isError = false;
      })
      .catch((err) => {
        this.isLoading = false;
        this.isError = true;

        console.log(err);
      });
  },
};
</script>

<style></style>
