<template>
  <div>
    <b-card v-if="isLoading">
      <loading />
    </b-card>
    <!-- <b-card v-if="isError">
      <error />
    </b-card> -->
    <b-card v-else :title="$t('g.maintenanceLog')">
      <div class="custom-search d-flex justify-content-between mb-1">
        <!-- search input -->
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t("g.searchLabel") }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('g.searchHere')"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
        <div>
          <router-link
            :to="{ name: 'AddMaintenanceRequest', params: { id: currentId } }"
            class="btn btn-primary d-block ml-auto"
            style="width: fit-content"
          >
            {{ $t("g.maintenanceRequest") }}
          </router-link>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.label === 'notes'" class="text-nowrap">
            {{ $t("g.notes") }}
          </span>
          <span v-else-if="props.column.label === 'status'" class="text-nowrap">
            {{ $t("g.status") }}
          </span>
          <span
            v-else-if="props.column.label === 'start_date'"
            class="text-nowrap"
          >
            {{ $t("g.start_date") }}
          </span>

          <span
            v-else-if="props.column.label === 'end_date'"
            class="text-nowrap"
          >
            {{ $t("g.end_date") }}
          </span>

          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>

          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'is_open_row'" class="text-nowrap">
            {{ props.row.is_open ? $t("g.open") : $t("g.close") }}
          </span>

          <span
            v-if="props.column.field === 'start_date_row'"
            class="text-nowrap"
          >
            {{ props.row.start_date ? format(props.row.start_date) : "-" }}
          </span>
          <span
            v-if="props.column.field === 'end_date_row'"
            class="text-nowrap"
          >
            {{ props.row.end_date ? format(props.row.end_date) : "-" }}
          </span>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span class="actions">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  :to="{
                    name: 'EditMaintenanceRequest',
                    params: { process_id: props.row.id, id: currentId },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteAlert(props.row.id)">
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <div slot="emptystate" class="text-center">{{ message }}</div>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template> </vue-good-table
    ></b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import formatDate from "@/libs/format-date";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Loading,
    Error,

    BDropdownItem,
    BDropdown,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      message:null,
      format: null,
      currentId: null,
      log: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "notes",
          field: "notes",
        },
        {
          label: "status",
          field: "is_open_row",
        },
        {
          label: "start_date",
          field: "start_date_row",
        },

        {
          label: "end_date",
          field: "end_date_row",
        },
        {
          label: "action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      isLoading: true,
      isError: false,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.format = formatDate;
    // get data from backend
    this.currentId = this.$route.params.id;
    this.getMaintenanceRequest(this.currentId);
  },
  methods: {
    getMaintenanceRequest(id) {
      this.$http
        .get(`admin/devices/${id}/maintainProcesses`)
        .then((res) => {
          if (res.data.message) {
            this.message = res.data.message;
          } else {
            this.rows = res.data.data;
          }
          this.isLoading = false;
          this.isError = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
        });
    },
    deleteProcess(id) {
      this.$http
        .delete(`admin/devices/${this.currentId}/maintainProcesses/${id}`)
        .then((res) => {
          this.getMaintenanceRequest(this.currentId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Sweet Alert
    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteProcess(id);
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
