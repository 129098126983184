<template>
  <div>
    <b-card no-body v-if="data" class="card-developer-meetup">
      <b-card-body>
        <b-row class="mb-2">
          <b-col cols="6">
            <h4>
              {{ $t("g.theDates") }}
            </h4> </b-col
          ><b-col cols="6">
            <div>
              <router-link
                :to="{ name: 'EquipmentCalibrationPlanList' }"
                class="btn btn-primary d-block ml-auto"
                style="width: fit-content"
              >
                {{ $t("g.calibration_plan_log") }}
              </router-link>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-media no-body class="">
              <b-media-aside>
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="ArrowDownCircleIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.dateOfArrival") }}</h5>
                <h5 class="mb-0">{{ format(data.data.arraival_date) }}</h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="ArrowRightCircleIcon" size="18" />
                </b-avatar>
              </b-media-aside>

              <b-media-body>
                <h5 class="mb-0">{{ $t("g.operatingDate") }}</h5>
                <h5 class="mb-0">{{ format(data.data.working_date) }}</h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.serviceEntryDate") }}</h5>
                <h5 class="mb-0">{{ format(data.data.serviceEntry_date) }}</h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body >
                <h5 class="mb-0">{{ $t("g.calibrationDate") }}</h5>
                <h5 class="mb-0">
                  {{
                    data.data.calibration_plan
                      ? format(
                          data.data.calibration_plan.created_at
                        )
                      : $t("g.noData")
                  }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
          <!-- <b-col md="3 mt-2">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.calibrationEndDate") }}</h5>
                <h5 class="mb-0">{{ data.data.serviceEntry_date }}</h5>
              </b-media-body>
            </b-media>
          </b-col> -->
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BMedia,
  BCardHeader,
  BButton,
} from "bootstrap-vue";
import formatDate from "@/libs/format-date";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BMediaAside,
    BCardBody,
    BMediaBody,
    BMedia,
    BCardHeader,
    BButton,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return{
      format: null
    }
  },
  created() {
    this.format = formatDate;
  },
};
</script>
