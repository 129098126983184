<template>
  <div>
    <b-card :title="$t('g.basicInformations')" class="info">
      <b-row>
        <b-col lg="12" class="mb-1">
          <span class="h5 text-secondary">
            <feather-icon icon="GitCommitIcon"> </feather-icon>
            {{ $t("g.specs") }} :
          </span>
          <span class="h5 text-secondary mr-1 ml-1">
            {{ data.data.specs }}
          </span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col lg="12" class="mb-1">
          <span class="h5 text-secondary">
            <feather-icon icon="GitCommitIcon"> </feather-icon>
            {{ $t("g.environmental_conditions") }} :
          </span>
          <span class="h5 text-secondary mr-1 ml-1">
            {{ data.data.environmental_conditions }}
          </span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col lg="12" class="mb-1">
          <span class="h5 text-secondary">
            <feather-icon icon="GitCommitIcon"> </feather-icon>
            {{ $t("g.test_standards") }} :
          </span>
          <span class="h5 text-secondary mr-1 ml-1">
            {{ data.data.test_standards }}
          </span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col lg="12" class="mb-1">
          <span class="h5 text-secondary">
            <feather-icon icon="GitCommitIcon"> </feather-icon>
            {{ $t("g.working_area") }} :
          </span>
          <span class="h5 text-secondary mr-1 ml-1">
            {{ data.data.working_area }}
          </span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col lg="12" class="mb-1">
          <span class="h5 text-secondary">
            <feather-icon icon="GitCommitIcon"> </feather-icon>
            {{ $t("g.device_attaches") }} :
          </span>
          <span class="h5 text-secondary mr-1 ml-1">
            {{ data.data.device_attaches }}
          </span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
  
  <script>
import { BAvatar, BBadge, BCard, BButton, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BAvatar,
    BBadge,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.info {
  hr {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
}
</style>
  